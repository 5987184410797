import React from "react"
import { useRealmApp } from "../context/RealmApp"

export function useCollection({ cluster = "mongodb-atlas", db, collection }) {
  const realmApp = useRealmApp() as any

  return React.useMemo(() => {
    if (!realmApp) return null
    const mdb = realmApp?.currentUser?.mongoClient("mongodb-atlas")
    return mdb?.db(db)?.collection(collection)
  }, [realmApp?.currentUser, cluster, db, collection])
}
