exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-chathistory-tsx": () => import("./../../../src/pages/chathistory.tsx" /* webpackChunkName: "component---src-pages-chathistory-tsx" */),
  "component---src-pages-disclaimer-tsx": () => import("./../../../src/pages/disclaimer.tsx" /* webpackChunkName: "component---src-pages-disclaimer-tsx" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-namespaces-tsx": () => import("./../../../src/pages/namespaces.tsx" /* webpackChunkName: "component---src-pages-namespaces-tsx" */),
  "component---src-pages-prompt-tsx": () => import("./../../../src/pages/prompt.tsx" /* webpackChunkName: "component---src-pages-prompt-tsx" */),
  "component---src-pages-references-tsx": () => import("./../../../src/pages/references.tsx" /* webpackChunkName: "component---src-pages-references-tsx" */),
  "component---src-pages-termofuse-tsx": () => import("./../../../src/pages/termofuse.tsx" /* webpackChunkName: "component---src-pages-termofuse-tsx" */)
}

