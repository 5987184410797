export const colors = {
  "red": {
    "50": "#faeaeb",
    "100": "#f5d6d7",
    "200": "#ecacae",
    "300": "#e28386",
    "400": "#d85a5e",
    "500": "#cf3238",
    "600": "#a5272b",
    "700": "#7c1d20",
    "800": "#531315",
    "900": "#290a0b",
    "default": "#cf3238",
    "full": "#cf3238"
  },
  "blue": {
    "50": "#e6f7ff",
    "100": "#cdeffe",
    "200": "#9adefe",
    "300": "#68cefd",
    "400": "#35befd",
    "500": "#03a9f4",
    "600": "#028bca",
    "700": "#026897",
    "800": "#014565",
    "900": "#012332",
    "default": "#03a9f4",
    "full": "#03a9f4"
  },
  "gray": {
    "50": "#f2f2f2",
    "100": "#e6e6e6",
    "200": "#cccccc",
    "300": "#b3b3b3",
    "400": "#999999",
    "500": "#808080",
    "600": "#666666",
    "700": "#4d4d4d",
    "800": "#333333",
    "900": "#1a1a1a",
    "default": "#f4f4f4",
    "full": "#f4f4f4"
  },
  "teal": {
    "50": "#eafaf9",
    "100": "#d6f5f2",
    "200": "#adebe6",
    "300": "#84e1d9",
    "400": "#5bd7cd",
    "500": "#3ad0c3",
    "600": "#28a49a",
    "700": "#1e7b73",
    "800": "#14524d",
    "900": "#0a2926",
    "default": "#3ad0c3",
    "full": "#3ad0c3"
  },
  "dark": {
    "50": "#f2f2f2",
    "100": "#e6e6e6",
    "200": "#cccccc",
    "300": "#b3b3b3",
    "400": "#999999",
    "500": "#808080",
    "600": "#666666",
    "700": "#4d4d4d",
    "800": "#333333",
    "900": "#1f1f1f",
    "default": "#1f1f1f",
    "full": "#1f1f1f"
  }
}