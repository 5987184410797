import { prompt } from "../../../.."
import { v4 as uuidv4 } from "uuid"

const newEmptyPrompt = () => {
  return {
    uuid: uuidv4(),
    name: "New Preset",
    document_type: "prompt",
    prompt: "",
    isSelected: false,
    settings: {
      model: "text-davinci-003",
      max_tokens: 1000,
      temperature: 0.7,
      frequency_penalty: 0,
      presence_penalty: 0,
      top_p: 1,
      stop: "",
    },
    description: "",
    isEmptyTemplate: true,
    tags: [],
    category: [],
    created_at: "",
    created_by: "",
    shared_with: [],
  } as prompt
}

export default newEmptyPrompt
