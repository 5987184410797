import React, { createContext, useState, useEffect } from "react"
import emptyScenario from "../components/schemas/newScenario"
import newEmptyPrompt from "../components/schemas/newPrompt"
import { prompt } from "../../../"
import { useRealmApp } from "./RealmApp"
import { useCollection } from "../hooks/useCollection"
import { useWatchCollection } from "../hooks/useWatchCollection"
import axios from "axios"

export const AppContext = createContext<ProviderValues>({} as ProviderValues)

const ContextProvider = ({ children }) => {
  /******************************************************************************/
  // Authentication
  /******************************************************************************/

  const { currentUser } = useRealmApp() as any
  const [loading, setLoading] = useState<boolean>(false)

  /******************************************************************************/
  // Prompts
  /******************************************************************************/

  const [prompts, setPrompts] = useState<prompt[]>([])
  const [selectedPrompt, setSelectedPrompt] = useState<prompt>()
  const [emptyPrompt, setEmptyPrompt] = useState<prompt>(newEmptyPrompt())
  const promptsCollection = useCollection({
    db: "vzchat",
    collection: "prompts",
  })

  useWatchCollection(promptsCollection, {
    // Callback for when a document is inserted
    onInsert: async change => {
      const { fullDocument } = change
      const newEmpty = newEmptyPrompt()
      const filtered = prompts.filter(prompt => {
        return prompt.uuid !== fullDocument.uuid
      })
      const updatedPrompts = [...filtered, fullDocument, newEmpty]
      setPrompts(updatedPrompts)
      setSelectedPrompt(fullDocument)
    },

    // Callback for when a document is updated
    onUpdate: async change => {
      const { fullDocument } = change
      const updated = prompts.map(doc => {
        if (doc.uuid === fullDocument.uuid) {
          return fullDocument
        }
        return doc
      })
      setPrompts(updated)
      setSelectedPrompt(fullDocument)
    },

    // Callback for when a document is deleted
    onDelete: async ({ documentKey }) => {
      const updated = prompts.filter((doc: any) => {
        return doc._id.toString() !== documentKey._id.toString()
      })
      setPrompts(updated)
    },
  })

  /******************************************************************************/
  // Fetch all data on load
  /******************************************************************************/
  useEffect(() => {
    const maxRetries = 2;
    let retryCount = 0;
  
    const fetchActivePrompts = async () => {
      setLoading(true);
      while (retryCount <= maxRetries) {
        try {
          const response = await axios.get(`${process.env.GATSBY_API_BASE_URL}/prompts`, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          setLoading(false);
          setSelectedPrompt(response.data.response.data[0]);
          setPrompts([...response.data.response.data, emptyPrompt]);
          return; // Exit the loop if the request is successful
        } catch (error) {
          setLoading(false);
          console.error(error);
          if (retryCount < maxRetries) {
            retryCount++;
            console.log(`Retry attempt ${retryCount}`);
          } else {
            console.log('Maximum number of retries reached.');
            break;
          }
        }
      }
    };
  
    fetchActivePrompts();
  }, []);
  

  return (
    <AppContext.Provider
      value={{
        emptyScenario,
        setPrompts,
        selectedPrompt,
        setSelectedPrompt,
        prompts,
        promptsCollection,
        loading,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default ContextProvider

interface ProviderValues {
  emptyScenario: any
  prompts: any
  setPrompts: any
  selectedPrompt: any
  setSelectedPrompt: any
  promptsCollection: any
  loading: boolean
}
