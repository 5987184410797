import React from "react"
import { IoSettingsOutline } from "react-icons/io5"
import { HiAdjustments } from "react-icons/hi"
import { FaRobot } from "react-icons/fa"
import { FiLogOut } from "react-icons/fi"

import { AiFillDashboard } from "react-icons/ai"
import { BsFillChatDotsFill } from "react-icons/bs"

import { CheckCircleIcon } from "@chakra-ui/icons"

import { navigate } from "gatsby"
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
  Icon,
  useDisclosure,
  HStack,
  VStack,
  Text,
  Heading,
} from "@chakra-ui/react"

import { useRealmApp } from "src/gatsby-theme-wild-child/context/RealmApp"

function Settings() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { logOut } = useRealmApp() as any

  const routes = [
    {
      name: "Chat",
      label: "Chat",
      icon: BsFillChatDotsFill,
      path: "/",
      access: "all",
    },
    {
      name: "Prompt",
      label: "Dial in personality",
      icon: FaRobot,
      path: "/prompt",
      access: "administrator",
    },
    {
      name: "Chat History",
      label: "Chat History",
      icon: BsFillChatDotsFill,
      path: "/chathistory",
      access: "administrator",
    },
    {
      name: "References",
      label: "Upload references",
      icon: HiAdjustments,
      path: "/references",
      access: "administrator",
    },
    {
      name: "Namespaces",
      label: "Manage Namespaces",
      icon: CheckCircleIcon,
      path: "/namespaces",
      access: "administrator",
    },
    {
      name: "Feedback",
      label: "User feedback",
      icon: AiFillDashboard,
      path: "/feedback",
      access: "administrator",
    },
  ]

  const handleLogout = () => {
    logOut()
  }

  return (
    <>
      <IconButton
        rounded="full"
        aria-label="settings"
        boxShadow="rgba(207, 51, 58, 0.1) 0px 10px 50px"
        position="absolute"
        left="30px"
        top="18px"
        size="lg"
        bg="gray.100"
        onClick={onOpen}
        icon={<Icon boxSize="26px" as={IoSettingsOutline} color="dark.full" />}
      />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerHeader fontSize="18px" bg="gray.100">
            Admin
          </DrawerHeader>

          <DrawerBody display="flex" alignContent="center">
            <VStack
              as="nav"
              spacing={12}
              justify="center"
              align="flex-start"
              py={8}
              borderTop="1px solid"
              borderBottom="1px solid"
              borderColor="whiteAlpha.800"
              w="full"
            >
              {routes.map(route => {
                return (
                  <HStack
                    w="full"
                    key={route.name}
                    spacing={4}
                    align="center"
                    px={0}
                    cursor="pointer"
                    rounded="full"
                    transition="all 0.2s ease-in-out"
                    onClick={() => {
                      navigate(route.path)
                      onClose()
                    }}
                  >
                    <IconButton
                      icon={
                        <Icon as={route.icon} color="red.full" boxSize="22px" />
                      }
                      aria-label={route.name}
                      bg="white"
                      rounded="full"
                      py="2px"
                      px="2px"
                      minW="40px"
                      minH="40px"
                      h="unset"
                      w="unset"
                      className="icon-button"
                    />
                    <VStack spacing="0" align="start">
                      <Text
                        mb="0!important"
                        color="gray.900"
                        fontSize="12px"
                        fontWeight="400"
                        _hover={{
                          color: "offwhite.full",
                        }}
                      >
                        {route.label}
                      </Text>
                      <Heading
                        as="h4"
                        mb={0}
                        py={0}
                        color="gray.900"
                        fontSize="18px"
                        fontWeight="600"
                        _hover={{
                          color: "offwhite.full",
                        }}
                      >
                        {route.name}
                      </Heading>
                    </VStack>
                  </HStack>
                )
              })}
            </VStack>
          </DrawerBody>

          <DrawerFooter textAlign="left" fontSize="18px" bg="gray.100">
            <IconButton
              icon={<FiLogOut />}
              aria-label="Logout"
              variant="ghost"
              onClick={handleLogout}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default Settings
