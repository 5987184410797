import React, { useRef } from "react"
import Button from "gatsby-theme-wild-child/src/components/Button"
import { RiWhatsappFill } from "react-icons/ri"
import { BsFillTelephoneFill } from "react-icons/bs"
import { useRealmApp } from "../../context/RealmApp"
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Link,
  Text,
  useBreakpointValue,
  useTheme,
  VStack,
  Icon,
} from "@chakra-ui/react"
import Settings from "../Settings"

function TopMenu({ menu }) {
  const isMobile = useBreakpointValue({ base: true, md: false })

  const navRef = useRef()
  const { variables } = useTheme()
  const buttonSize = useBreakpointValue(["sm", "sm", "lg"])
  const { currentUser } = useRealmApp()
  const isAdmin =
    currentUser?._profile?.data?.email?.toLowerCase().includes("damian") ||
    currentUser?._profile?.data?.email?.toLowerCase().includes("wild")

  return (
    <Box
      w="full"
      position="fixed"
      left={0}
      right={0}
      top={0}
      ref={navRef}
      bg={["white", "white", "gray.full"]}
      display={isMobile ? "none" : "block"}
    >
      <Flex
        as="header"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        w="100%"
        bg="transparent"
        zIndex="sticky"
        h={variables.navHeight}
        px={variables.sectionPaddingX}
      >
        <Grid
          flexDirection="column"
          layerStyle="fillSpace"
          gap={[4, 4, 4, 8, 10, 20]}
          templateColumns={[
            "repeat(3, 1fr)",
            "repeat(3, 1fr)",
            `100px 1fr auto`,
            `170px 1fr auto`,
          ]}
          maxW="100%"
          maxH={variables.navHeight}
        >
          <GridItem
            gridRow={["1/2", "1/2", "1/2"]}
            gridColumn={["2/3", "2/3", "1/2"]}
            py={0}
            px={[2, 2, 0]}
          >
            {isAdmin && <Settings />}
            <Center h="full" w="full">
              <Link href="/">
                <Image
                  src="https://res.cloudinary.com/wild-creative/image/upload/v1673849636/virtuzone-logo-1_fchemo.svg"
                  w="auto"
                  h="60px"
                  display={["none", "none", "block"]}
                />
              </Link>
            </Center>
          </GridItem>

          <GridItem sx={{ display: ["none", "none", "flex"] }}>
            {menu && (
              <HStack justify="flex-start" align="center" h="full" spacing={0}>
                {menu?.menuItems?.nodes
                  .filter(item => {
                    return !item.parentId
                  })
                  .map(item => {
                    return !item.childItems.nodes.length ? (
                      <Center
                        as={Link}
                        key={`nav-link-${item.id}`}
                        href={item.path}
                        isExternal
                        h="100%"
                        paddingX={[2, 2, 2, 2, 4]}
                        display="flex"
                        align="center"
                        justify="center"
                        transition="all 0.2s ease-in-out"
                      >
                        <Text
                          py={2}
                          fontSize={["14px", "14px", "14px", "14px", "15px"]}
                          fontWeight="semibold"
                          color="dark.full"
                          sx={{
                            "&.menu-item-text": { mb: "0 !important" },
                          }}
                          className="menu-item-text"
                        >
                          {item.label}
                        </Text>
                      </Center>
                    ) : (
                      <Center
                        key={`nav-link-${item.id}`}
                        position="relative"
                        h="full"
                        _hover={{
                          ".dropdown-menu": {
                            visibility: "visible",
                          },
                        }}
                      >
                        <Center
                          h="100%"
                          paddingX={4}
                          display="flex"
                          align="center"
                          justify="center"
                          transition="all 0.2s ease-in-out"
                        >
                          <Text
                            py={2}
                            fontSize="15px"
                            fontWeight="semibold"
                            color="white"
                            sx={{
                              "&.menu-item-text": { mb: "0 !important" },
                            }}
                            _hover={{
                              bgGradient:
                                "linear(to-r,secondary.default 0%,lightRed.600 50%, primary.600)",
                              bgClip: "text",
                            }}
                            className="menu-item-text"
                          >
                            {item.label}
                          </Text>
                        </Center>
                        <Box
                          position="absolute"
                          bgGradient="linear(67.1deg,dark.default 7.3%, #090909 89.3%)"
                          borderColor="dark.700"
                          visibility="hidden"
                          roundedBottom="md"
                          borderTop="1px solid"
                          top="100%"
                          left={0}
                          boxShadow="sm"
                          className="dropdown-menu"
                        >
                          <VStack
                            as="nav"
                            minW="300px"
                            alignItems="flex-start"
                            py={6}
                            px={4}
                          >
                            {item.childItems.nodes.map(child => {
                              return (
                                <Link
                                  as={Link}
                                  isExternal
                                  key={`nav-link-${child.id}`}
                                  paddingX={4}
                                  href={child.path}
                                  position="relative"
                                  _hover={{
                                    ".dropdown-menu": {
                                      visibility: "visible",
                                    },
                                  }}
                                >
                                  <Text
                                    py={2}
                                    fontSize="15px"
                                    fontWeight="semibold"
                                    color="white"
                                    _hover={{
                                      bgGradient:
                                        "linear(to-r,secondary.default 0%,lightRed.600 50%, primary.600)",
                                      bgClip: "text",
                                    }}
                                    sx={{
                                      "&.menu-item-text": {
                                        mb: "0 !important",
                                      },
                                    }}
                                  >
                                    {child.label}
                                  </Text>
                                </Link>
                              )
                            })}
                          </VStack>
                        </Box>
                      </Center>
                    )
                  })}
              </HStack>
            )}
          </GridItem>
          <GridItem
            gridRow={["1/2", "1/2", "1/2"]}
            gridColumn={["3/4", "3/4", "3/4"]}
            display="flex"
            justifyContent={["flex-end", "flex-end"]}
            alignItems={["center", "center"]}
            py={0}
            px={[2, 2, 0]}
            h="full"
            gap={6}
          >
            <HStack
              h="full"
              isExternal
              align="center"
              spacing={2}
              as={Link}
              to="tel:+97145973690"
            >
              <Icon as={BsFillTelephoneFill} boxSize="22px" color="red.full" />
              <Text
                fontSize="15px"
                display={["none", "none", "none", "none", "block"]}
              >
                +97145973690
              </Text>
            </HStack>
            <HStack

              h="full"
              isExternal
              align="center"
              spacing={2}
              as={Link}
              to='https://api.whatsapp.com/send?phone=971526542690'
              >
              <Icon as={RiWhatsappFill} boxSize="25px" color="#25D366" />
              <Text
                fontSize="15px"
                display={["none", "none", "none", "none", "block"]}
              >
                WhatsApp
              </Text>
            </HStack>
            <Button
              to="https://calendly.com/virtuzone-cs/corporate-tax-consultation?utm_source=TaxGPT&utm_medium=Website&utm_campaign=CorporateTaxCalendly"
              size={buttonSize}
              isExternal={true}
              bg="red.full"
              display={["block", "block", "none", "inline-flex"]}
            >
              Free Tax Consultation
            </Button>
          </GridItem>
        </Grid>
      </Flex>
    </Box>
  )
}

export default TopMenu
