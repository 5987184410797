export const variables = {
  /*** NAV ***/
  topMenuHeight: "115px",
  navHeight: ["70px", "70px", "85px"],
  siteMarginTop: ["0"],
  sideMenuWidth: "0",
  logoHeight: "100%",
  logoWidth: ["140px", "140px", "140px", "170px"],

  /*** SECTIONS ***/
  contentMaxWidth: "1200px",
  sectionPaddingY: [0],
  sectionPaddingX: [5, 5, 12, 20, 24, 40],
  containerPaddingY: [20, 20, 32],
  containerPaddingX: [0],
  componentSpacing: [4, 8, 8, 8, 8, 8, 8],
  rowSpacing: [8, 8, 12, 12, 16, 20, 20],
}
