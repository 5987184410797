import { Box } from "@chakra-ui/react"
import React, {
  useEffect,
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react"
import * as Realm from "realm-web"

const baseUrl = "https://realm.mongodb.com"

function createRealmApp(id) {
  return new Realm.App({ id, baseUrl })
}

const RealmAppContext = createContext(null) as any

export function RealmAppProvider({ children }) {
  const appId = "chatvz-ydmzg"
  const [realmApp, setRealmApp] = useState(createRealmApp(appId))
  const [currentUser, setCurrentUser] = useState(realmApp.currentUser)
  const [loading, setLoading] = useState(false)

  /************************************/
  /*** Functions
  /************************************/
  const logIn = useCallback(
    async (email, password) => {
      setLoading(true)
      const credentials = Realm.Credentials.emailPassword(email, password)
      await realmApp.logIn(credentials)
      setCurrentUser(realmApp.currentUser)
      setLoading(false)
    },
    [realmApp]
  )

  const logOut = useCallback(async () => {
    try {
      const user = realmApp.currentUser as any
      await user?.logOut()
      await realmApp.removeUser(user)
    } catch (err) {
      console.error(err)
    }

    setCurrentUser(realmApp.currentUser)
  }, [realmApp])

  /************************************/
  /*** Realm App Initialization  *****/
  /************************************/
  useEffect(() => {
    setRealmApp(createRealmApp(appId))
  }, [appId])

  useEffect(() => {
    // log in anonymously if no user is logged in
    if (!currentUser) {
      setLoading(true)
      realmApp.logIn(Realm.Credentials.anonymous()).then(setCurrentUser)
      setLoading(false)
    }
  }, [realmApp])

  const realmAppContext = useMemo(() => {
    return {
      ...realmApp,
      currentUser,
      logIn,
      logOut,
      loading,
      setLoading,
    }
  }, [realmApp, currentUser, logIn, logOut, loading, setLoading])

  return (
    <RealmAppContext.Provider value={realmAppContext}>
      <Box bg={"red.200"}>{children}</Box>
    </RealmAppContext.Provider>
  )
}

export function useRealmApp() {
  const realmApp = useContext(RealmAppContext) || {
    currentUser: null,
    logIn: async () => {},
    logOut: async () => {},
    loading: false,
    setLoading: () => {},
  }

  if (!realmApp) {
    throw new Error(
      `No Realm App found. Make sure to call useRealmApp() inside of a <RealmAppProvider />.`
    )
  }
  return realmApp
}
