export const textStyles = {
  fadeTextLeft: {
    color: "gray.100",
    position: "absolute",
    opacity: "0.5",
    zIndex: "-1",
    top: ["-15vw", "-15vw", "-7vw"],
    left: ["-9vw", "-9vw", "-3vw"],
    letterSpacing: "-20px",
    fontWeight: "800",
    fontSize: "220px",
    display: ["none", "none", "block"],
  },
  fadeTextCenter: {
    color: "primary.300",
    position: "absolute",
    opacity: ["0.6", "0.6", "0.4"],
    zIndex: "-1",
    left: 0,
    fontSize: ["50px", "15vw", "100px", "160px", "200px", "220px"],
    fontWeight: ["extrabold", "extrabold", "extrabold", "bold"],
    justifyContent: "space-between",
    w: "100%",
    px: ["5%", "4%", "9%"],
    top: ["3%", "3%", "50px", "0"],
    textTransform: "uppercase",
  },
  fadeTextCenterGreen: {
    color: "primary.200",
    position: "absolute",
    opacity: ["0.6", "0.6", "0.5"],
    zIndex: "-1",
    left: 0,
    fontSize: ["50px", "15vw", "100px", "160px", "200px", "220px"],
    fontWeight: ["extrabold", "extrabold", "extrabold", "bold"],
    justifyContent: "space-between",
    w: "100%",
    px: ["5%", "4%", "9%"],
    top: ["30px", "30px", "50px", "0", "-2%", "-5%"],
    textTransform: "uppercase",
  },
}
