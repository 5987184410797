import { Input as defaultInput } from "gatsby-theme-wild-child/src/@chakra-ui/gatsby-plugin/components/input.js"

let variants = defaultInput.variants;
variants = { 
  ...variants, 
  filled: {
    bg: "white"
  },
  unfilled: {
    bg: "#F6F6F6"
  },
  redfull: {
    bg: "red.full"
  }
};

export const Input = {
  ...defaultInput,
  variants
}
