import React, { useEffect, useMemo } from "react"

// By default we won't do anything for a change event unless the caller passed in a change handler
// for the change event type.
const noop = () => {}
const defaultChangeHandlers = {
  onInsert: noop,
  onUpdate: noop,
  onReplace: noop,
  onDelete: noop,
}

export function useWatchCollection(collection, changeHandlers) {
  const filter = useMemo(() => ({}), [])
  const handlers = { ...defaultChangeHandlers, ...changeHandlers }
  // We copy the handlers into a ref so that we can always call the latest version of each handler
  // without causing a re-render when the callbacks change. This can prevent infinite render loops
  // that would otherwise happen if the caller doesn't memoize their change handler functions.
  const handlersRef = React.useRef(handlers)
  useEffect(() => {
    handlersRef.current = {
      onInsert: handlers.onInsert,
      onUpdate: handlers.onUpdate,
      onReplace: handlers.onReplace,
      onDelete: handlers.onDelete,
    }
  }, [
    handlers.onInsert,
    handlers.onUpdate,
    handlers.onReplace,
    handlers.onDelete,
  ])

  // Set up a MongoDB change stream that calls the provided change handler callbacks.
  useEffect(() => {
    let stream
    const watch = async () => {
      stream = collection?.watch({ filter })
      if (!stream) return
      for await (const change of stream) {
        switch (change.operationType) {
          case "insert": {
            handlersRef.current.onInsert(change)
            break
          }
          case "update": {
            handlersRef.current.onUpdate(change)
            break
          }
          case "replace": {
            handlersRef.current.onReplace(change)
            break
          }
          case "delete": {
            handlersRef.current.onDelete(change)
            break
          }
          default: {
            // change.operationType will always be one of the specified cases, so we should never hit this default
            throw new Error(
              `Invalid change operation type: ${change.operationType}`
            )
          }
        }
      }
    }
    watch()
    return () => {
      // Close the change stream in the effect cleanup
      stream?.return()
    }
  }, [collection, filter])
}
