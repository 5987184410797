const SITE_CONFIG = {
  siteUrl: "http://localhost:9000/",
  siteTitle: "VZChat",
  siteDescription: "Landing page for VZChat",

  /**** THEME OPTIONS ****/
  cssVarPrefix: "chatvz",

  /**** BUILD OPTIONS ****/
  createPosts: false,
  createPages: false,
  addSiteMap: false,
  submitSitemapAuto: false,
  useWpRedirects: false,
  templateExtension: ".js",
  googleTagManagerId: "GTM-P9VZVCR",
}

module.exports = SITE_CONFIG
