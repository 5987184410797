import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyCKtWVjIujOhGiQNlCZZtuxqG2rQSjz4EU",
    authDomain: "chatvz.firebaseapp.com",
    databaseURL: "https://chatvz-default-rtdb.firebaseio.com",
    projectId: "chatvz",
    storageBucket: "chatvz.appspot.com",
    messagingSenderId: "1098742426133",
    appId: "1:1098742426133:web:3b49d29796e78499fdbc1c",
    measurementId: "G-FEDWBJFC37"
  };

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export default database;
