import { scenario } from "../../../.."

const newEmptyVariable: scenario = {
  _id: "",
  uuid: "",
  type: "intent",
  category: "All",
  status: {
    approved: false,
    approved_by: "",
    approved_at: "",
    rejected: false,
    rejected_by: "",
    rejected_at: "",
  },
  created_at: "",
  created_by: "",
  references: [],
  chat_summary: "",
  prompt: [
    { visitor_message_1: "" },
    { chatvz_message_1: "" },
    { visitor_message_2: "" },
  ],
  completion: [
    { text: "", selected: false },
    { text: "", selected: false },
    { text: "", selected: false },
  ],
  isEmptyTemplate: true,
}

export default newEmptyVariable
