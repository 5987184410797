/*** DEPENDENCIES ***/
import React, { useState } from "react"
import "../assets/MyFontsWebfontsKit.css"
import Main from "gatsby-theme-wild-child/src/components/Main"
import ContextProvider from "../context/ContextProvider"
import { ChatProvider } from "./chat/ChatProvider"
import Nav from "./nav"
import { RealmAppProvider } from "../context/RealmApp"

export interface LayoutProps {
  children?: React.ReactNode
}

export const MenuContext = React.createContext({}) as React.Context<any>

function Layout({ children }: LayoutProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false) // Menu state
  const [navPosition, setNavPosition] = useState("") // Nav position

  return (
    <RealmAppProvider>
      <ContextProvider>
        <ChatProvider>
          <MenuContext.Provider
            value={{
              isMenuOpen,
              setIsMenuOpen,
              navPosition,
              setNavPosition,
            }}
          >
            <Nav />
            <Main>{children}</Main>
          </MenuContext.Provider>
        </ChatProvider>
      </ContextProvider>
    </RealmAppProvider>
  )
}

export default Layout
